.getstarted {
    // padding-top: calc(var(--navbar-height) + 4rem);
    padding-top: 100px;
    margin-top: calc(var(--navbar-height) - var(--navbar-height) * 2);
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    padding-bottom:30px;
    color: var(--quaternary-color);
    display: flex;
    background-size: cover;

    &_left-side {
        width: 60%;

        &_subtitle {
            margin-top: 2rem;
        }

        &_actions {
            margin-top: 3rem;
        }
    }

    &_right-side {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_image {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .getstarted {
        padding-top: calc(var(--navbar-height) + 3rem);

        &_left-side {
            width: 100%;
    
            &_subtitle {
                margin-top: 2rem;
            }
    
            &_actions {
                margin-top: 3rem;
            }
        }

        &_right-side {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .getstarted {
        padding-top: calc(var(--navbar-height) + 20px);
    }
}