.ats-system {
    color: var(--quaternary-color);
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--secondary-color);

    &-content {
        box-sizing: border-box;
        width: 100%;
        --item-width: 300px;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, minmax(var(--item-width), 1fr));
        // max-width: calc(300px * 3 + 10px * 2);
        margin-top: 50px;

        &--card {
            display: flex;
            width: var(--item-width);
            gap: 10px;
            height: min-content;
            margin: auto;
            margin-bottom: 60px;

            &-icon {
                width: 15%;
            }

            &-content {
                width: 70%;

                &__title {
                    margin-bottom:7.5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .ats-system {
        &-content {
            grid-template-columns: repeat(2, minmax(var(--item-width), 1fr));
        }
    }
}

@media only screen and (max-width: 768px) {
    .ats-system {
        &-content {
            grid-template-columns: repeat(1, minmax(var(--item-width), 1fr));
        }
    }
}