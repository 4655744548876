.download {
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    color: var(--quaternary-color);
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
    margin-top: 20px;

    &-description {
        margin-top: 20px;
    }

    &-templates {
        display: flex;
        overflow-x: auto;
        gap: 35px;
        padding: 20px;
        margin-top: 30px;
        margin-bottom:40px;
        background-color: rgb(223,223,223);

        &::-webkit-scrollbar {
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #161616;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #aeaeae;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #ffffff;
        }

        &-template {
            min-width: 450px;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 0px 6px 2px rgba(175, 202, 250, 0.547);
            }

            &--selected {
                box-shadow: 0px 0px 0px 6px rgba(42, 116, 255, 0.703)!important;
            }

            &-image {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .download {
        &-templates {
            &-template {
                min-width: 400px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .download {
        padding-top: 30px;
        &-templates {
            &-template {
                min-width: 300px;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .download {
        &-templates {
            &-template {
                min-width: 250px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .download {
        margin-top: 0px;
        
        &-templates {
            &-template {
                min-width: 200px;
            }
        }
    }
}