.templates {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: var(--quaternary-color);
    background-color: rgb(182, 182, 182);
    
    &-content {
        margin-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}