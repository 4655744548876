.card {
    width: 225px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
        width: 120px;
    }

    &-title {
        margin-top: 3rem;
        color: var(--primary-color);
    }

    &-description {
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .card {    
        &-description {
            text-align: center;
        }
    }
}