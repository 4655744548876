.contacts-container {
    background-color: var(--quinary-color);
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:5px;
}

@media only screen and (max-width: 768px) {
    .contacts-container {
        gap: 40px;
    }
}