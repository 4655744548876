.checkbox {
    display: flex;
    gap:0.5rem;
    align-items: center;
    justify-content: left;

    &-input {
        accent-color: var(--secondary-color);
        width: 1rem;
        height: 1rem;
        margin: 0px;
    }
}