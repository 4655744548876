.skills-container {
    background-color: var(--quinary-color);
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
    width: 800px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:5px;
}

.divider {
    width: 50%;
}

@media only screen and (max-width: 992px) {
    .skills-container {
        width: 100%;
    }

    .divider {
        width: 0%;
    }
}