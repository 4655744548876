.badge {
    display: flex;
    align-items: end;
    background-color: black;
    padding: 0.25rem 0.75rem 0.45rem 0.85rem;
    border-radius: 1.5rem;
    width: max-content;
    background-color: var(--badge-color);
    cursor: pointer;
    gap: 0.35rem;

    &:hover {
        background-color: var(--badge-color);
    }

    &-remove {
        height: 16px;
        width: 16px;
    }
}