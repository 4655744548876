.button {
    padding: 0.55rem 1.35rem;
    min-width: 6.5rem;
    border: none;
    border-radius: 0.4rem;
    color:black;
    background: none;
    cursor: pointer;
    border: 0.2rem solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    // &:disabled{
    //     opacity: 0.5;
    //     cursor: unset;
    // }
    
    &--primary {
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        &:hover {
            border-color: var(--primary-color--hover);
            background-color: var(--primary-color--hover);
        }
    }

    &--action {
        border-color: var(--action-color);
        background-color: var(--action-color);
        color:white;

        &:hover {
            border-color: var(--action-color--hover);
            background-color: var(--action-color--hover);
        }
    }

    &--dark {
        border-color: var(--quaternary-color);
        background-color: var(--quaternary-color);
        color:white;

        &:hover {
            border-color: var(--quaternary-color--hover);
            background-color: var(--quaternary-color--hover);
        }
    }

    &--danger {
        border-color: var(--danger-color);
        background-color: var(--danger-color);
        color:white;

        &:hover {
            border-color: var(--danger-color--hover);
            background-color: var(--danger-color--hover);
        }
    }

    &--outlined {
        background-color: transparent;
    }
}