:root {
    --page-padding-y: 1rem;
    --page-padding-x: 250px;
    --navbar-height: 72px;
    
    --background-color: rgba(198,199,194,255);

    --primary-color: rgba(115,103,107,255);
    --primary-color--hover: rgba(135,123,137,255);

    --secondary-color: white;
    --secondary-color--hover: rgba(176,159,142,255);

    --tertiary-color: rgba(147,149,136,255);
    --tertiary-color--hover: rgba(127,129,116,255);
    --tertiary-color--focus: rgba(97,99,86,255);

    --quaternary-color: rgb(62, 62, 62);
    --quaternary-color--hover: rgba(76,71,67,255);

    --quinary-color: rgb(220,215,206);

    --secondary-text-color: white;
    --tertiary-text-color: black;
    --quaternary-text-color: rgba(233,233,233,255);

    --about-icon-color: rgb(128, 128, 248);

    --selected-step-color: rgba(202,192,180,255);
    --unselected-step-color: rgba(162,152,140,255);

    --danger-color: rgb(205, 81, 93);
    --danger-color--hover: rgb(199, 66, 79);

    --badge-color: rgba(186,169,162,255);

    --action-color: #248eff;
    --action-color--hover: rgb(5, 121, 244);

    --footer-color: rgb(189, 189, 189);
}

@media only screen and (max-width: 1400px) {
    :root {
        --page-padding-x: 150px;
    }
}

@media only screen and (max-width: 1200px) {
    :root {
        --page-padding-x: 100px;
    }
}

@media only screen and (max-width: 992px) {
    :root {
        --page-padding-x: 35px;
        --navbar-height: 60px;
    }
}