.faq {
    color: var(--quaternary-color);
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--secondary-color);
    height: auto;

    &-content {
        margin-top: 2rem;
        padding-left: 200px;
        padding-right: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 1600px) {
    .faq {
        &-content {
            padding-left: 150px;
            padding-right: 150px;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .faq {
        &-content {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .faq {
        &-content {
            padding-left: 75px;
            padding-right: 75px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .faq {
        &-content {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .faq {
        &-content {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}