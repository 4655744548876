.accordion {
    border-bottom: 1px solid rgba(0,0,0,0.3);

    &-header {
        padding-top: 22.5px;
        padding-bottom: 22.5px;
        cursor: pointer;

        &:hover {
            color:var(--action-color);
        }
    }

    &-content {
        padding-bottom: 20px;
    }

    &-opened {
        display: block;
    }

    &-closed {
        display: none;
    }
}