.navbar {
    width: 100%;
    height: var(--navbar-height);
    padding: 0rem var(--page-padding-x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    // background-color: var(--quinary-color);
    background-color: transparent;
    z-index: 9;
    color: white;

    &_left-side {
        display: flex;

        &_logo {
            cursor: pointer;
            
            &-text{
                font-size: 32px;
            }
        }
    }

    &_right-side {
        display: flex;
        gap: 20px;

        &_sections-container {
            display: flex;

            &_section {
                display: flex;
                padding: 0rem 1rem;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    border-bottom: 1px solid var(--primary-color);
                }

                &-link {
                    font-weight: 200;
                }
            }
        }

        &_actions-container {
            display: flex;
            gap: 2rem;
        }
    }
}

.navbar-black {
    color: black;

    &_right-side {
        &_sections-container {
            &_section {
                &-link {
                    color: black;
                }
            }
        }
    }
}

.navbar-white {
    color: white;

    &_right-side {
        &_sections-container {
            &_section {
                &-link {
                    color: white;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .navbar {    
        background-color: black;
        color: white;
        
        &_right-side {
            &_actions-container {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .navbar {    
        display: flex;
        align-items: center;

        &_left-side {
            &_logo {         
                &-text{
                    font-size: 24px;
                }
            }
        }

        &_right-side {
            display: none;
        }
    }
}