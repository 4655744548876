.font {
    font-weight: 700;

    &-state--danger {
        color: var(--danger);
    }

    &-state--action {
        color: var(--indigo);
    }

    &-size {
        &--sm {
            font-size: 0.9rem;
        }

        &--m {
            font-size: 1.2rem;
        }

        &--lg {
            font-size: 1.4rem;
        }
    }

    &-weight {
        &--sm {
            font-weight: 200;
        }

        &--m {
            font-weight: 400;
        }

        &--lg {
            font-weight: 700;
        }
    }

    &-align {
        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }
    }
}