.resume-generator {
    color:var(--quaternary-color);
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    display: flex;

    flex-direction: column;
    align-items: start;

    gap: 50px;
    
    &__section {
        display: flex;
        flex-direction: column;

        &-form {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;

            &__actions {
                margin-bottom: 2rem;
                width: 100%;
                display: flex;
                justify-content: start;
            }

            &__line {
                display: flex;
                gap: 3rem;
                flex-wrap: wrap;

                &--w100 {
                    width: 100%;
                }
            }

            &__checkbox {
                margin-bottom: 1rem;
            }

            &__subsection {
                border-bottom: 1px solid var(--tertiary-color);
                padding-bottom:1rem;
                margin-bottom:2rem;
            }
        }

        &-navigation {
            display: flex;
            gap: 2rem;
            margin-bottom: 1rem;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .resume-generator {
        &__section {
            &-form {
                &__line {
                    gap: 2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .resume-generator {
        &__section {
            width: 100%;
            align-items: left;
        }
    }
}

@media only screen and (max-width: 768px) {
    .resume-generator {
        margin-top: 0px;
        
        &__section {
            &-form {
                &__line {
                    gap: 1rem;
                }
            }
        }
    }
}