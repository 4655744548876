.home-title {
    font-size: 64px;
    color: white;
}

.home-subtitle {
    color: rgb(220,220,220);
}

.section-title {
    font-size: 48px;
    text-align: center;
    max-width: 70%;
    margin: auto;
}

@media only screen and (max-width: 1200px) {
    .home-title {
        font-size: 56px;
    }

    .section-title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .home-title {
        font-size: 35px;
        font-weight: 500;
    }

    .section-title {
        font-size: 27.5px;
        font-weight: 500;
        max-width: 90%;
    }
}