.input {
    display: flex;
    flex-direction: column;
    flex: 1;

    &--textarea {
        width: 100%;
    }

    &-title {
        padding-left: 0.1rem;
        margin-bottom:0.5rem;
    }

    &-field {
        background-color: rgb(200,200,200);
        border: 1px solid var(--tertiary-color);
        padding-left: 0.5rem;
        width: auto;
        height: 2rem;
        outline: none;
        color:rgb(54, 54, 54);
        border-radius: 0.5rem;
        -moz-appearance: textfield;
        appearance: none;

        &-date {
            width: -webkit-fill-available;
        }

        &--textarea {
            height: 6rem;
            width: 100%;
            resize: none;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        &:hover:not(&-state--danger) {
            border: 1px solid var(--tertiary-color--hover);
        }
    
        &:focus:not(&-state--danger) {
            border: 1px solid var(--tertiary-color--focus);
        }

        &-state {
            &--danger {
                border-color: var(--danger-color);
            }
        }
    }

    &-errors {
        padding-top: 0.4rem;
        padding-left: 0.1rem;
        height: 40px;
    }
}

@media only screen and (max-width: 1200px) {
    .input {
        &-errors {
            height: 20px;
        }
    }
}