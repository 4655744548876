html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: 100%;
}

body {
  margin: 0;
  background-color: white;
  min-height: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

#container {
  min-height: 100%;
}

p, h1 {
  margin:0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
