.slick-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick {
    --total-items: 4;
    --item-width: 300px;
    --item-margin: 5px;

    overflow-x: hidden;
    width: calc(var(--item-width) * var(--total-items) + var(--item-margin) * 2 * var(--total-items));
    height: auto;
    margin: 0px 20px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &-container {
        display: flex;
        position: relative;
        transition-duration: 0.5s;
    }

    &-item {
        min-width: var(--item-width);
        margin: 0px var(--item-margin);
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &--image {
            width: 100%;
        }

        &-selector {
            position: absolute;
            display: none;
        }

        &:hover {
            opacity: 0.5;
        }

        &:hover > &-selector {
            display: block;
        }
    }

    &-arrow {
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        background-color: var(--tertiary-color);
        border-radius: 50%;

        &:hover {
            background-color: var(--tertiary-color--hover);
        }
    }

    &-empty-arrow {
        width: 40px;
        height: 40px;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 50%;
    }
}

@media only screen and (max-width: 1400px) {
    .slick {
        --total-items: 3;
        --item-width: 275px;
    }
}

@media only screen and (max-width: 992px) {
    .slick {
        --total-items: 2;
    }
}

@media only screen and (max-width: 768px) {
    .slick {
        --total-items: 1;
    }
}

@media only screen and (max-width: 450px) {
    .slick {
        --item-width: 250px;
        margin: 0px 10px;
    }
}

@media only screen and (max-width: 400px) {
    .slick {
        --item-width: 225px;
    
        &-arrow {
            width: 35px;
            height: 35px;
            padding: 10px 10px 10px 10px;
        }
    
        &-empty-arrow {
            width: 35px;
            height: 35px;
            padding: 10px 10px 10px 10px;
        }
    }
}