.progress {
    --circle-radius: 30px;
    --step-width: 80px;
    --line-width: 100px;

    display: flex;

    &-step {
        width: var(--step-width);
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        &__circle {
            width: var(--circle-radius);
            height: var(--circle-radius);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--unselected-step-color);
            border: 1px solid var(--tertiary-color);
        }

        &--current > &__circle {
            background-color: var(--selected-step-color);
        }
    }

    &-line{
        width:var(--line-width);
        height: 1px;
        background-color: var(--tertiary-color);
        margin-top: calc(var(--circle-radius) / 2);
        position: relative;
        margin-left: calc(calc(var(--step-width) / -2) - calc(var(--circle-radius) / -2) + 1px);
        margin-right: calc(calc(var(--step-width) / -2) - calc(var(--circle-radius) / -2) + 1px);
    }
}

.progress-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 992px) {
    .progress-container{
        display: none;
    }
}