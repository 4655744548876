.about {
    color: var(--quaternary-color);
    padding-left: var(--page-padding-x);
    padding-right: var(--page-padding-x);
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: var(--secondary-color);
    height: auto;

    &-content {
        margin-top: 7rem;
        display: flex;
        align-items: start;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 768px) {
    .about {
        &-content {
            margin-top: 5rem;
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }
    }
}